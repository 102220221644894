import { XMarker } from './XMarker';

type PeaksProps = {
  data: any[];
  color: string;
};

export const Peaks = ({ data, color }: PeaksProps) => {
  return (
    <>
      {data.map((position, i) => {
        const [lng, lat] = position;
        return <XMarker color={color} key={`${lat}_${lng}`} position={[lat, lng]} size={20} />;
      })}
    </>
  );
};
