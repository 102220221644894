import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { fetchDataByUrl } from '@helpers/fetchData';
import { useEffect, useState } from 'react';
import { FeatureCollection } from 'geojson';

export const useFlippedScanPath = (
  analyzedEntity: AnalyzedEntity | undefined,
  depndencies: any[]
): FeatureCollection | null => {
  const [value, setValue] = useState<FeatureCollection | null>(null);

  useEffect(() => {
    if (!analyzedEntity?.id || !analyzedEntity?.scan_path_url_override) {
      setValue(null);
      return;
    }

    fetchDataByUrl<FeatureCollection>(analyzedEntity?.scan_path_url_override).then((data) => {
      setValue(data);
    });
  }, depndencies);

  return value;
};
