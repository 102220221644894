import { CSSProperties } from 'react';

export enum Signals {
  XAmplitude1 = 'x-amplitude-1',
  XAmplitude2 = 'x-amplitude-2',
  YAmplitude1 = 'yamplitude',
  YAmplitude1Peak = 'y-amplitude-1',
  YAmplitude2 = 'y-amplitude-2',
  ZAmplitude1 = 'zamplitude',
  ZAmplitude1Peak = 'z-amplitude-1',
  ZAmplitude2 = 'z-amplitude-2',
  PeakNorm1 = 'norm(x-amplitude-1,y-amplitude-1)',
  PeakNorm2 = 'norm(x-amplitude-2,y-amplitude-2)'
}

export const TRACES_COLORS: Record<Signals, CSSProperties['color']> = {
  [Signals.XAmplitude1]: 'blue',
  [Signals.XAmplitude2]: 'red',
  [Signals.YAmplitude1]: 'orange',
  [Signals.YAmplitude2]: 'purple',
  [Signals.ZAmplitude1]: 'green',
  [Signals.ZAmplitude2]: 'brown',
  [Signals.YAmplitude1Peak]: 'orange',
  [Signals.ZAmplitude1Peak]: 'green',
  [Signals.PeakNorm1]: '#f0329c',
  [Signals.PeakNorm2]: '#000000'
};
export const DEFAULT_PEAKS_COLOR = 'gray';
