import React, { useContext, useMemo } from 'react';
import { MapButtonsContainer, Text, Row } from './styles';
import Switch from '@components/Switch';
import { Option } from '@components/SelectInput';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import Select, { MultiValue } from 'react-select';
import { OTHERUTILITIES, OtherUtilitiesType, otherUtilitiesOptions } from '../constants';
import { useMapControlsStore } from '@pages/Main/store';
import { PeaksOption, PeaksSelect } from '@components/PeaksSelect';
import { ScanPathSelect } from '@components/ScanPathSelect';

interface Props {
  isOrthophotoLayerOn: boolean;
  onChangeOrthophotoLayerOn: (value: boolean) => void;
  isScanPathLayerOn: boolean;
  onChangeScanPathLayerOn: (value: boolean) => void;
  otherUtilitiesView: string;
  setOtherUtilitiesView: (value: OtherUtilitiesType) => void;
  interpolationOptions: Option[];
  selectedInterpolationId: Option['key'];
  setSelectedInterpolationId: (key: Option['key']) => void;
  isCrossingIndex: boolean;
  isYPeaks: boolean;
  onChangeYPeaks: (value: boolean) => void;
  onChangeCrossingIndex: (value: boolean) => void;
  isFixedScanPath: boolean;
  onChangeFixedScanPath: (value: boolean) => void;
  peaks: Record<string, any> | undefined;
  setSelectedPeaks: (peaks: PeaksOption[]) => void;
  selectedPeaks: PeaksOption[];
}

const MapLayerToggles: React.FC<Props> = (props) => {
  const {
    isOrthophotoLayerOn,
    onChangeOrthophotoLayerOn,
    isScanPathLayerOn,
    onChangeScanPathLayerOn,
    otherUtilitiesView,
    setOtherUtilitiesView,
    interpolationOptions,
    selectedInterpolationId,
    setSelectedInterpolationId,
    isCrossingIndex,
    isYPeaks,
    onChangeYPeaks,
    onChangeCrossingIndex,
    isFixedScanPath,
    onChangeFixedScanPath,
    peaks,
    selectedPeaks,
    setSelectedPeaks
  } = props;
  const { depthList } = useContext(AnalyzedEntityContext);
  const { interpolation, toggleInterpolation, currentPipe, toggleCurrentPipe } =
    useMapControlsStore();

  const depthOptions: Option[] = Object.keys(depthList).map((val) => {
    return { key: val, value: val };
  });

  depthOptions.push({ key: 'NONE', value: 'NONE' });

  const handlePeaksChange = (newValue: MultiValue<PeaksOption>) => {
    setSelectedPeaks([...newValue]);
  };

  return (
    <MapButtonsContainer>
      <Row>
        <Select
          id="ss"
          menuPlacement="top"
          name="Interoplation"
          onChange={(newVal) => setSelectedInterpolationId(newVal!.key)}
          options={interpolationOptions}
          getOptionLabel={(opt) => opt.value}
          getOptionValue={(opt) => opt.key}
          value={interpolationOptions.find((o) => o.key === selectedInterpolationId)}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (provided) => ({
              ...provided,
              maxWidth: 250,
              width: 250
            })
          }}
        />
      </Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '15px'
        }}>
        <span style={{ display: 'flex', gap: '5px' }}>
          <Row style={{ padding: '5px' }}>
            <Switch onChange={toggleCurrentPipe} checked={currentPipe} />
            <Text>Current Pipe</Text>
          </Row>
          <Row style={{ padding: '5px' }}>
            <Switch onChange={toggleInterpolation} checked={interpolation} />
            <Text>Interpolation</Text>
          </Row>

          {!peaks && (
            <Row style={{ padding: '5px' }}>
              <Switch onChange={onChangeYPeaks} checked={isYPeaks} />
              <Text>Y Peaks</Text>
            </Row>
          )}
        </span>
        <span style={{ display: 'flex', gap: '5px' }}>
          {/* <Row style={{ padding: '5px' }}>
            <Switch onChange={onChangeScanPathLayerOn} checked={isScanPathLayerOn} />
            <Text>Scan Path</Text>
          </Row>
          <Row style={{ padding: '5px' }}>
            <Switch
              onChange={onChangeFixedScanPath}
              checked={isFixedScanPath && isScanPathLayerOn}
              disabled={!isScanPathLayerOn}
            />
            <Text className={!isScanPathLayerOn ? 'disabled' : ''}>Fixed Scan Path</Text>
          </Row> */}
          <Row style={{ padding: '5px' }}>
            <Switch onChange={onChangeOrthophotoLayerOn} checked={isOrthophotoLayerOn} />
            <Text>Orthophoto</Text>
          </Row>
          <Row style={{ padding: '5px' }}>
            <Switch onChange={onChangeCrossingIndex} checked={isCrossingIndex} />
            <Text>Crossing Index</Text>
          </Row>
        </span>
      </div>
      <Row style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
        <Text>Scan Path:</Text>
        <ScanPathSelect />
      </Row>
      {peaks && (
        <Row style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
          <Text>Select peaks:</Text>
          <PeaksSelect peaks={peaks} onChange={handlePeaksChange} selectedPeaks={selectedPeaks} />
        </Row>
      )}

      <Row style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
        <Text>Other Utilities:</Text>
        <Select
          onChange={(newVal) =>
            setOtherUtilitiesView((newVal?.value as OtherUtilitiesType) || OTHERUTILITIES.ALL)
          }
          value={otherUtilitiesOptions.find((c) => c.value === otherUtilitiesView)}
          getOptionLabel={(opt) => opt.label}
          getOptionValue={(opt) => opt.value}
          options={otherUtilitiesOptions}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuPlacement="top"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (provided) => ({
              ...provided,
              maxWidth: 150,
              width: 150
            })
          }}
        />
      </Row>
    </MapButtonsContainer>
  );
};

export default MapLayerToggles;
