import React, { lazy, Ref, RefObject, useEffect, useRef, useState } from 'react';
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Pane, TileLayer } from 'react-leaflet';
import { InterpolationZ } from './Interpolation';
import ScanPath from './ScanPath';
import Utility from './Utility';
import { FeatureCollection } from 'geojson';
import ZoomHandler from './ZoomHandler';
import Orthophoto from './Orthophoto';
import { mapConfig } from '@constants/mapConfigs';
import OtherUtilities, { OtherUtilityData } from '@components/Map/OtherUtilities';
import DepthList from '@components/Map/DepthList';
import TransmitterList from '@components/Map/TransmitterList';
import { PipeCrossings } from './PipeCrossings';
import { useZoomLevel } from '@hooks/useZoomLevel';
import { YPeaks } from './YPeaks';
import { CrossingPipe } from '@hooks/prefetch/usePipesCrossings';
import { isPointInPolygon, zIndexes } from './utils';
import { PolygonLayer } from './Polygon';
import { MapControls } from './Controls';
import { PolygonKeys, useCrossingViewStore, useEditPipeStore } from '@store';
import { OTHERUTILITIES, OtherUtilitiesType } from '@pages/Main/ControlButtons/constants';
import type { CoordinateGeoJsonObject } from 'src/types/geojson';
import { EditPipe } from './EditPipe';
import { useMapControlsStore } from '@pages/Main/store';
import ActiveCrossingPoint from './ActiveCrossingPoint/ActiveCrossingPoint';
import { Peaks } from './Peaks';
import {
  DEFAULT_PEAKS_COLOR,
  Signals,
  TRACES_COLORS
} from '@pages/Main/DetailsView/CrossingViews/constans';

const Interpolation = lazy(async () => import('./Interpolation'));

export interface InterpolationData {
  bbox_transformed: [[number, number], [number, number]];
  bbox_original: [[number, number], [number, number]];
  interpolation: InterpolationZ;
}

interface Props {
  interpolationData?: InterpolationData;
  yPeaks: LatLngExpression[];
  utilityData: CoordinateGeoJsonObject;
  otherUtilitiesData?: OtherUtilityData[];
  otherUtilitiesSameFrequency?: OtherUtilityData[];
  otherUtilitiesView: OtherUtilitiesType;
  center?: LatLngExpression;
  isOrthophotoLayerOn: boolean;
  isInterpolationLayerOn: boolean;
  isScanPathLayerOn: boolean;
  isCrossingIndex: boolean;
  isYPeaks: boolean;
  minInterpolation: number;
  maxInterpolation: number;
  mapRef?: Ref<L.Map>;
  pipesCrossing: CrossingPipe[];
  selectedPeakTypes: string[];
  peaks: Record<string, any> | undefined;
}

const Map: React.FC<Props> = (props) => {
  const {
    interpolationData,
    utilityData,
    otherUtilitiesData,
    otherUtilitiesSameFrequency,
    otherUtilitiesView,
    center,
    isScanPathLayerOn,
    isOrthophotoLayerOn,
    isInterpolationLayerOn,
    maxInterpolation,
    minInterpolation,
    mapRef,
    isCrossingIndex,
    pipesCrossing,
    isYPeaks,
    yPeaks,
    selectedPeakTypes,
    peaks
  } = props;
  const zoom = useZoomLevel();
  const addPolygonCrossingIndex = useCrossingViewStore((s) => s.addPolygonCrossingIndex);
  const removePolygonCrossingIndex = useCrossingViewStore((s) => s.removePolygonCrossingIndex);
  const editMode = useEditPipeStore((state) => state.editMode);
  const { interpolation, currentPipe } = useMapControlsStore();

  const showLayer = zoom.zoomLevel >= 22;

  const handleCreatePolygon = (polygon: [number, number][], key: PolygonKeys) => {
    const pipesCrossingIndex = pipesCrossing
      .filter((pc) => isPointInPolygon(polygon, [pc?.lat, pc?.lon]))
      .map((s) => s.crossing_index);
    if (!pipesCrossing.length) return;
    addPolygonCrossingIndex(pipesCrossingIndex);
  };

  const handleRemovePolygon = (polygon: [number, number][], key: PolygonKeys) => {
    const pipesCrossingIndex = pipesCrossing
      .filter((pc) => isPointInPolygon(polygon, [pc?.lat, pc?.lon]))
      .map((s) => s.crossing_index);
    if (!pipesCrossing.length) return;
    removePolygonCrossingIndex(pipesCrossingIndex);
  };

  return (
    <MapContainer
      key="map"
      id="map"
      ref={mapRef}
      center={center}
      zoom={mapConfig.zoom}
      style={{
        height: '100%',
        width: '100%'
      }}
      maxZoom={mapConfig.maxZoom}>
      <Pane name="TileLayer" style={{ zIndex: zIndexes.TILELAYER }}>
        <TileLayer
          maxZoom={mapConfig.maxZoom}
          maxNativeZoom={mapConfig.maxNativeZoom}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </Pane>
      <Pane name="ZoomHandler" style={{ zIndex: zIndexes.ZOOMHANDLER }}>
        <ZoomHandler />
      </Pane>
      {isInterpolationLayerOn && interpolationData && interpolation && (
        <Pane name="Interpolation" style={{ zIndex: zIndexes.INTERPOLATION }}>
          <Interpolation
            transformedBox={interpolationData.bbox_transformed}
            interpolation={interpolationData.interpolation}
            maxInterpolation={maxInterpolation}
            minInterpolation={minInterpolation}
          />
        </Pane>
      )}
      {isYPeaks && showLayer && (
        <Pane name="YPeaks" style={{ zIndex: zIndexes.Y_PEAKS }}>
          <Peaks data={yPeaks} color={TRACES_COLORS[Signals.YAmplitude1] || DEFAULT_PEAKS_COLOR} />
        </Pane>
      )}
      {selectedPeakTypes.map((peakType) => (
        <Pane key={peakType} name={`Peak-${peakType}`} style={{ zIndex: zIndexes.Y_PEAKS }}>
          <Peaks
            data={peaks?.[peakType] || []}
            color={TRACES_COLORS[peakType as Signals] || DEFAULT_PEAKS_COLOR}
          />
        </Pane>
      ))}
      {isCrossingIndex && showLayer && !editMode && (
        <Pane name="PipeCrossings" style={{ zIndex: zIndexes.PIPE_CROSSINGS }}>
          <PipeCrossings data={pipesCrossing} />
        </Pane>
      )}
      {editMode && showLayer && (
        <Pane name="EditPipe" style={{ zIndex: zIndexes.EDIT_PIPE }}>
          <EditPipe utilityData={utilityData} key={JSON.stringify(utilityData)} />
        </Pane>
      )}
      {currentPipe && (
        <Pane name="Utility" style={{ zIndex: zIndexes.UTILITY }}>
          <Utility utilityData={utilityData} />
        </Pane>
      )}
      <Pane name="ActiveCrossingPoint" style={{ zIndex: zIndexes.ACTIVE_CROSSING_POINT }}>
        <ActiveCrossingPoint />
      </Pane>
      {showLayer && (
        <Pane name="DepthList" style={{ zIndex: zIndexes.DEPTH_LIST }}>
          <DepthList />
        </Pane>
      )}
      {isScanPathLayerOn && (
        <Pane name="ScanPath" style={{ zIndex: zIndexes.SCAN_PATH }}>
          <ScanPath />
        </Pane>
      )}
      {isOrthophotoLayerOn && (
        <Pane name="Orthophoto" style={{ zIndex: zIndexes.ORTHOPHOTO }}>
          <Orthophoto />
        </Pane>
      )}
      {otherUtilitiesView === OTHERUTILITIES.SAME_FREQUENCY ? (
        <Pane name="OtherUtilities" style={{ zIndex: zIndexes.OTHER_UTILITIES }}>
          <OtherUtilities otherUtilitiesData={otherUtilitiesSameFrequency} />
        </Pane>
      ) : otherUtilitiesView === OTHERUTILITIES.ALL ? (
        <Pane name="OtherUtilities" style={{ zIndex: zIndexes.OTHER_UTILITIES }}>
          <OtherUtilities otherUtilitiesData={otherUtilitiesData} />
        </Pane>
      ) : null}
      <Pane name="TransmitterList" style={{ zIndex: zIndexes.TRANSMITTER_LIST }}>
        <TransmitterList />
      </Pane>
      <PolygonLayer onCreate={handleCreatePolygon} onRemove={handleRemovePolygon} />
      <MapControls />
    </MapContainer>
  );
};

export default Map;
