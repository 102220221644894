import React, { useEffect, useRef } from 'react';
import Map from '@components/Map';
import { OtherUtilityData } from '@components/Map/OtherUtilities';
import { Container, MapWrapper } from './styles';
import Header from './Header';
import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { useMapControlsStore } from '../store';
import { useGetInterpolationData } from '@hooks/prefetch/interpolation';
import { useYPeaks } from '@hooks/prefetch/useYPeaks';
import { useScanPath } from '@hooks/prefetch/useScanPath';
import { useFixedScanPath } from '@hooks/prefetch/useFixedScanPath';
import { useUtilityData } from '@hooks/prefetch/useUtilityData';
import { usePipesCrossings } from '@hooks/prefetch/usePipesCrossings';
import { useMapLoaderStore, useScanPathStore } from '@store';
import { MapLoaderIndicator } from '@components/Map/MpaLoaderIndicator/MapLoaderIndicator';
import { useZoomLevel } from '@hooks/useZoomLevel';
import { useOtherUtilitiesSameFrequency } from '@hooks/prefetch/useOtherUtilitiesSameFrequency';
import { OtherUtilitiesType } from '../ControlButtons/constants';
import { useFlippedScanPath } from '@hooks/prefetch/useFlippedScanPath';
import { ScanPath } from './constants';

const centerLat = 40.820410399000004;
const centerLng = -72.957328127;

export type Option = {
  value: string;
  label: string;
};
interface Props {
  isOrthophotoLayerOn: boolean;
  isInterpolationLayerOn: boolean;
  isScanPathLayerOn: boolean;
  isCrossingIndex: boolean;
  isYPeaks: boolean;
  isFixedScanPath: boolean;
  otherUtilitiesData?: OtherUtilityData[];
  interpolationType?: string;
  analyzedEntity: AnalyzedEntity;
  analyzedEntityList: AnalyzedEntity[];
  otherUtilitiesView: OtherUtilitiesType;
  selectedPeakTypes: string[];
  peaks: Record<string, any> | undefined;
}

const MapView: React.FC<Props> = (props) => {
  const {
    isOrthophotoLayerOn,
    isInterpolationLayerOn,
    isScanPathLayerOn,
    otherUtilitiesData,
    isCrossingIndex,
    isYPeaks,
    interpolationType,
    analyzedEntity,
    analyzedEntityList,
    otherUtilitiesView,
    isFixedScanPath,
    selectedPeakTypes,
    peaks
  } = props;

  const interpolationData = useGetInterpolationData(
    { analyzedEntityList, analyzedEntity, interpolationType },
    [analyzedEntity?.id, interpolationType, analyzedEntityList.length]
  )?.read();

  const setScanPathOptions = useScanPathStore((store) => store.setScanPathOptions);
  const setScanPath = useScanPathStore((store) => store.setScanPath);
  const selectedScanPath = useScanPathStore((store) => store.selectedScanPath);
  const yPeaks = useYPeaks(analyzedEntity, [analyzedEntity?.scan_frequency?.y_peaks_view]);
  const scanPathData = useScanPath(analyzedEntity, [analyzedEntity?.scan?.scan_path_url]);
  const otherUtilitiesSameFrequency = useOtherUtilitiesSameFrequency(
    analyzedEntity,
    otherUtilitiesData || []
  );
  const fixedScanPathData = useFixedScanPath(analyzedEntity, [
    analyzedEntity?.scan?.scan_path_fix_mode_view
  ]);
  const flippedScanPathData = useFlippedScanPath(analyzedEntity, [
    analyzedEntity?.scan_path_url_override
  ]);
  useEffect(() => {
    const options: Option[] = [];
    if (scanPathData?.features && scanPathData?.features?.length > 0) {
      options.push({ value: ScanPath.Original, label: 'Original Scan Path' });
    }
    if (fixedScanPathData?.features && fixedScanPathData?.features?.length > 0) {
      options.push({ value: ScanPath.Fixed, label: 'Fixed Scan Path' });
    }
    if (flippedScanPathData?.features && flippedScanPathData?.features?.length > 0) {
      options.push({ value: ScanPath.Flipped, label: 'Flipped Scan Path' });
    }
    setScanPathOptions(options);
  }, [scanPathData, fixedScanPathData, flippedScanPathData]);
  useEffect(() => {
    if (!selectedScanPath.value) return;

    let activeScanPathData;
    switch (selectedScanPath.value) {
      case ScanPath.Fixed:
        activeScanPathData = fixedScanPathData;
        break;
      case ScanPath.Flipped:
        activeScanPathData = flippedScanPathData;
        break;
      case ScanPath.Original:
      default:
        activeScanPathData = scanPathData;
        break;
    }
    if (activeScanPathData) {
      setScanPath(activeScanPathData);
    }
  }, [selectedScanPath, scanPathData, fixedScanPathData, flippedScanPathData, setScanPath]);
  const utilityDataJson = useUtilityData(analyzedEntity, [analyzedEntity?.geometry]);
  const pipesCrossing = usePipesCrossings(analyzedEntity, [analyzedEntity?.pipe_crossings]);
  const loaders = useMapLoaderStore((state) => state.loaders);
  const mapRef = useRef<L.Map | null>(null);

  const { zoomLevel } = useZoomLevel();
  const { maxZoom, minZoom } = useMapControlsStore();

  const zoomToCenter = (): void => {
    if (!utilityDataJson) {
      return;
    }

    if (!mapRef.current) {
      return;
    }

    const coordinates = utilityDataJson.coordinates[0];
    if (!coordinates) {
      return;
    }
    mapRef.current.setView([coordinates[1], coordinates[0]], zoomLevel);
  };

  useEffect(() => {
    utilityDataJson && zoomToCenter();
  }, [utilityDataJson]);

  return (
    <Container>
      <Header onClickCenter={zoomToCenter} />
      <MapWrapper>
        {!!Object.keys(loaders).length && <MapLoaderIndicator loaders={loaders} />}
        <Map
          mapRef={mapRef}
          isCrossingIndex={isCrossingIndex}
          isScanPathLayerOn={isScanPathLayerOn}
          isOrthophotoLayerOn={isOrthophotoLayerOn}
          otherUtilitiesView={otherUtilitiesView}
          isInterpolationLayerOn={isInterpolationLayerOn}
          isYPeaks={isYPeaks}
          yPeaks={yPeaks}
          interpolationData={interpolationData}
          utilityData={utilityDataJson}
          otherUtilitiesData={otherUtilitiesData}
          otherUtilitiesSameFrequency={otherUtilitiesSameFrequency}
          center={[centerLat, centerLng]}
          maxInterpolation={maxZoom / 100}
          minInterpolation={minZoom / 100}
          pipesCrossing={pipesCrossing}
          selectedPeakTypes={selectedPeakTypes}
          peaks={peaks}
        />
      </MapWrapper>
    </Container>
  );
};

export default MapView;
