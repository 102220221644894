import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { fetchDataByUrl } from '@helpers/fetchData';
import { useEffect, useState } from 'react';

export const usePeaks = (analyzedEntity: AnalyzedEntity | undefined, dependecies: any[]) => {
  const [value, setValue] = useState();
  useEffect(() => {
    if (!analyzedEntity?.id) return;
    const src = analyzedEntity?.scan_frequency?.generic_peaks_view;
    if (!src) return;

    fetchDataByUrl(src)
      .then((res: any) => {
        setValue(res.peaks_pinpoints);
      })
      .catch((err) => {
        console.error(err);
      });
  }, dependecies);
  return value;
};
