import type { Scan } from '@graphql/generated';
import { type Option, SELECT_ALL } from './MultiSelect';

export const getCreatedAtOptions = (scans: Scan[], selectedScanDates: Scan[]): Option[] => {
  const createAtSet = new Set<string>();
  createAtSet.add(SELECT_ALL);
  scans.forEach((scan) => {
    if (
      selectedScanDates.length === 0 ||
      selectedScanDates.some((selectedScan) => selectedScan.id === scan.id)
    ) {
      scan?.analyzed_entities?.items.forEach((entity) => {
        if (entity?.createdAt) {
          const dateOnly = entity.createdAt.split('T')[0];
          createAtSet.add(dateOnly);
        }
      });
    }
  });

  const createdAt = Array.from(createAtSet).map((date) => ({
    label: date,
    value: date
  }));

  return createdAt.sort((a, b) => {
    if (a.value === SELECT_ALL) return -1;
    if (b.value === SELECT_ALL) return 1;
    return new Date(a.value).getTime() - new Date(b.value).getTime();
  });
};
