import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { useEffect, useMemo, useState } from 'react';
import { InterpolationData } from '@components/Map';
import { fetchInterpolationData } from './fetch';
import { useInterpolationDataStore, useMapLoaderStore } from '@store';
import { getBearerToken } from '@helpers/auth';
import { ProgressLoader } from '@components/ProgressLoader';
import axios from 'axios';
import pako from 'pako';

function expandSparseInterpolation(sparseData: {
  sizeX: number;
  sizeY: number;
  values: Array<[number, number, ...number[]]>;
}) {
  const { sizeX, sizeY, values } = sparseData;

  // Create an empty matrix filled with nulls
  const fullMatrix: (number | null)[][] = Array(sizeY)
    .fill(null)
    .map(() => Array(sizeX).fill(null));

  // Fill in the non-null values
  values.forEach((run) => {
    if (run.length < 3) return; // Skip invalid runs

    const rowIndex = run[0];
    let colIndex = run[1];

    // Fill consecutive values starting from position [rowIndex, colIndex]
    for (let i = 2; i < run.length; i++) {
      if (rowIndex < sizeY && colIndex < sizeX) {
        fullMatrix[rowIndex][colIndex] = run[i];
      }
      colIndex++; // Move horizontally
    }
  });

  return fullMatrix;
}

export const DEFUALT_INTERPOLATION_VALUE = 'y-amplitude-1_None';
export const FIXED_INTERPOLATION_VALUES: string[] = [
  'z-amplitude-1_None',
  'norm(x-amplitude-1,y-amplitude-1)_None',
  DEFUALT_INTERPOLATION_VALUE
];

export const getInterpolations = ({
  defaultInterpolations,
  type
}: {
  defaultInterpolations: string[];
  type: string;
}) => {
  return Array.from(new Set([...defaultInterpolations, type]));
};

export const getItems = ({ analyzedEntity }: { analyzedEntity: AnalyzedEntity }) => {
  return analyzedEntity.scan_frequency?.interpolations?.items || [];
};
export const getSparseItems = ({ analyzedEntity }: { analyzedEntity: AnalyzedEntity }) => {
  return analyzedEntity.scan_frequency?.sparse_interpolations?.items || [];
};

export const getCurrentTypeUrl = ({ items, type }: { items: any; type: string }) => {
  const currentTypeUrl =
    items.find((item: any) => item?.interpolation_type === type)?.interpolation_url || '';
  return currentTypeUrl;
};

export const getUrls = ({
  items,
  interpolationsToFetch
}: {
  items: any[];
  interpolationsToFetch: string[];
}) => {
  const urls = [];
  for (const item of items) {
    if (interpolationsToFetch.includes(item.interpolation_type)) urls.push(item.interpolation_url);
  }
  return urls;
};

export const useGetInterpolationData = (
  {
    analyzedEntity,
    analyzedEntityList,
    interpolationType
  }: {
    analyzedEntityList: (AnalyzedEntity | undefined)[];
    analyzedEntity: AnalyzedEntity | undefined;
    interpolationType?: string;
  },
  dependencies: any[]
) => {
  const [value, setValue] = useState<{ read: () => InterpolationData | undefined } | undefined>(
    undefined
  );

  function useExpandedInterpolation(sparseData: {
    sizeX: number;
    sizeY: number;
    values: Array<[number, number, ...number[]]>;
  }) {
    const expandedData = useMemo(() => {
      if (!sparseData) {
        return [];
      }

      return expandSparseInterpolation(sparseData);
    }, [sparseData]);

    return expandedData;
  }

  useEffect(() => {
    if (!analyzedEntity || !interpolationType) return;
    if (interpolationType === 'NONE') return setValue({ read: () => undefined });
    const updateStore = ({ urls, data }: { urls: string[]; data: InterpolationData[] }) => {
      urls.forEach((url, i) => {
        if (currentTypeUrl === url) setValue({ read: () => res });
        const res = data[i];
        if (!Object.keys(res).length) return;
        store.setInterpolationData(url, res);
      });
    };
    const items = getItems({ analyzedEntity });
    const sparesItems = getSparseItems({ analyzedEntity });

    if (!items.length && !sparesItems.length) return;

    const currentTypeUrl = getCurrentTypeUrl({ items, type: interpolationType });
    const store = useInterpolationDataStore.getState();
    const mapLoadersStore = useMapLoaderStore.getState();

    const abortController = new AbortController();

    const fetchSparseInterpolation = async (url: string) => {
      const loaderId = 'sparse-interpolations';
      try {
        const token = await getBearerToken();

        const response = await axios.get(url, {
          headers: { Authorization: token }
        });

        const file_name = response.data.url;
        const responseGZ = await axios.get(file_name, {
          responseType: 'arraybuffer'
        });
        const uint8Array = new Uint8Array(responseGZ.data);

        let decompressedData;
        try {
          decompressedData = pako.ungzip(uint8Array);
          const decompressedString = new TextDecoder('utf-8').decode(decompressedData);

          const sparseData = JSON.parse(decompressedString);

          if (!sparseData?.interpolation) {
            console.error('Invalid sparse interpolation data format', sparseData);
            return;
          }

          const expandedMatrix = expandSparseInterpolation(sparseData.interpolation);

          const processedData = {
            ...sparseData,
            interpolation: expandedMatrix,
            rawSparse: sparseData
          };

          if (!Object.keys(processedData).length) return;
          store.setInterpolationData(url, processedData);
          setValue({ read: () => processedData });

          return processedData;
        } catch (err) {
          console.error('Error decompressing data:', err);
        }
      } catch (err: any) {
        console.error('Error fetching sparse interpolation:', err);
      } finally {
        mapLoadersStore.removeLoader(loaderId);
      }
    };

    const fetchCurrentInterpolation = async (url: string) => {
      const loaderId = 'interpolations';

      mapLoadersStore.addLoader({
        id: loaderId,
        value: <ProgressLoader progress={0} />
      });

      try {
        const token = await getBearerToken();
        const dataURL = await fetch(url, {
          headers: {
            Authorization: token
          },
          signal: abortController.signal
        });
        const URL = await dataURL.json();
        const response = await fetch(URL.url, {
          signal: abortController.signal
        });

        const reader = response.body?.getReader();
        const contentLength = +(response.headers.get('Content-Length') || 0);
        let receivedLength = 0;
        const chunks = [];

        if (reader) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }

            chunks.push(value);
            receivedLength += value.length;

            const progress = Math.floor((receivedLength / contentLength) * 100);
            mapLoadersStore.addLoader({
              id: loaderId,
              value: <ProgressLoader progress={progress} />
            });
          }

          const chunksAll = new Uint8Array(receivedLength);
          let position = 0;
          for (const chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
          }

          const jsonText = new TextDecoder('utf-8').decode(chunksAll);
          const jsonData = JSON.parse(jsonText);
          if (!Object.keys(jsonData).length) return;
          store.setInterpolationData(url, jsonData);
          setValue({ read: () => jsonData });
        }
      } catch (err: any) {
        console.log(err);
      } finally {
        mapLoadersStore.removeLoader(loaderId);
      }
    };

    const fetchCurrentEntityNextInterpolations = async () => {
      const interpolationsToFetch = FIXED_INTERPOLATION_VALUES.filter(
        (s) => s !== interpolationType
      );
      const urls = getUrls({ items, interpolationsToFetch }).filter(
        (s) => !store.getInterpolation(s)
      );
      if (!urls.length) return;
      const data = await fetchInterpolationData(urls, abortController);
      if (!data) return;
      updateStore({ urls, data });
    };

    const fetchNext = async () => {
      if (!analyzedEntityList || analyzedEntityList.length <= 1 || !analyzedEntity.id) return;
      let startIndex = analyzedEntityList.findIndex((s) => s?.id === analyzedEntity.id) + 1;
      if (startIndex >= analyzedEntityList.length) startIndex = 0;
      if (startIndex <= 0) return;
      for (let i = startIndex; i < startIndex + 4; i++) {
        const currentEntity = analyzedEntityList[i];
        if (!currentEntity) continue;
        const items = currentEntity.scan_frequency?.interpolations?.items || [];
        const urls = getUrls({ items, interpolationsToFetch: FIXED_INTERPOLATION_VALUES }).filter(
          (s) => !store.getInterpolation(s)
        );
        if (!urls.length) continue;
        const data = await fetchInterpolationData(urls, abortController);
        if (!data) continue;
        updateStore({ urls, data });
      }
    };

    const fetchInterpolations = async () => {
      if (!sparesItems.length && !items.length) return;
      if (sparesItems.length) {
        const url =
          sparesItems.find((s) => s?.interpolation_type === interpolationType)?.interpolation_url ??
          sparesItems[0]?.interpolation_url;
        if (!url) return;
        await fetchSparseInterpolation(url.endsWith('.gz') ? url.replace('..', '.') : `${url}.gz`);
      } else {
        await fetchCurrentInterpolation(currentTypeUrl);
      }
      await fetchCurrentEntityNextInterpolations();
      await fetchNext();
    };

    const existingData = store.getInterpolation(currentTypeUrl);
    if (existingData) {
      setValue({ read: () => existingData });
      fetchNext();
      return;
    }

    fetchInterpolations();

    return () => {
      abortController.abort();
    };
  }, dependencies);

  return value;
};
