import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import { useContext, useEffect } from 'react';
import { useScanPathStore, ScanPathOption } from '@store';
import Select, { SingleValue } from 'react-select';
import { ScanPath } from '@pages/Main/MapView/constants';

export const ScanPathSelect = () => {
  const { analyzedEntity } = useContext(AnalyzedEntityContext);
  const scanPathOptions = useScanPathStore((store) => store.scanPathOptions);
  const selectedScanPath = useScanPathStore((store) => store.selectedScanPath);
  const setSelectedScanPath = useScanPathStore((store) => store.setSelectedScanPath);

  useEffect(() => {
    if (scanPathOptions.length > 0) {
      const currentSelectionExists = scanPathOptions.some(
        (opt) => opt.value === selectedScanPath.value
      );
      if (!currentSelectionExists || selectedScanPath.value === '') {
        const flippedOption = scanPathOptions.find((opt) => opt.value === ScanPath.Flipped);
        if (flippedOption) {
          setSelectedScanPath(flippedOption);
        } else {
          const originalOption = scanPathOptions.find((opt) => opt.value === ScanPath.Original);
          setSelectedScanPath(originalOption || scanPathOptions[0]);
        }
      }
    }
  }, [scanPathOptions, analyzedEntity?.id]);

  const handleChange = (newValue: SingleValue<ScanPathOption>) => {
    if (newValue) setSelectedScanPath(newValue);
  };
  return (
    <Select
      options={scanPathOptions}
      value={selectedScanPath}
      onChange={handleChange}
      placeholder="Select scan path"
      menuPosition="fixed"
      menuPlacement="top"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          maxWidth: 150,
          width: 150
        }),
        multiValue: (base) => ({
          ...base,
          minWidth: '120px',
          maxWidth: '200px'
        }),
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '180px',
          title: base.children
        })
      }}
    />
  );
};
