import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';

export const useInterpolationOptions = (analyzedEntity?: AnalyzedEntity) => {
  if (!analyzedEntity) return [];

  const interpolationOptions =
    analyzedEntity?.scan_frequency?.interpolations?.items
      .map((item) => {
        return {
          value: item?.interpolation_type || '',
          key: item?.interpolation_type || ''
        };
      })
      .sort((a, b) => a.value.localeCompare(b.value)) || [];

  interpolationOptions.push({
    key: 'NONE',
    value: 'NONE'
  });

  return interpolationOptions;
};
