import type { Scan } from '@graphql/generated';
import { UNKNOWN, type Option } from './MultiSelect';

export const getPipelinesOptions = (scans: Scan[], selectedScanDates: Scan[]): Option[] => {
  const pipelineSet = new Set<string>();

  scans.forEach((scan) => {
    if (
      selectedScanDates.length === 0 ||
      selectedScanDates.some((selectedScan) => selectedScan.id === scan.id)
    ) {
      scan?.analyzed_entities?.items.forEach((entity) => {
        if (entity?.pipeline_name) {
          pipelineSet.add(entity.pipeline_name.toString());
        } else {
          pipelineSet.add(UNKNOWN);
        }
      });
    }
  });

  return Array.from(pipelineSet).map((pipeline) => ({
    value: pipeline,
    label: pipeline
  }));
};
