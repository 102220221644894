import { create } from 'zustand';
import { FeatureCollection } from 'geojson';
export type ScanPathOption = {
  value: string;
  label: string;
};

type ScanPathStore = {
  scanPath: FeatureCollection;
  setScanPath: (scanPath: FeatureCollection) => void;
  scanPathOptions: ScanPathOption[];
  setScanPathOptions: (scanPathOptions: ScanPathOption[]) => void;
  selectedScanPath: ScanPathOption;
  setSelectedScanPath: (selectedScanPath: ScanPathOption) => void;
};

export const useScanPathStore = create<ScanPathStore>((set) => ({
  scanPath: { type: 'FeatureCollection', features: [] },
  setScanPath: (scanPath) => set({ scanPath }),
  scanPathOptions: [],
  setScanPathOptions: (scanPathOptions) => set({ scanPathOptions }),
  selectedScanPath: { value: '', label: '' },
  setSelectedScanPath: (selectedScanPath) => set({ selectedScanPath })
}));
