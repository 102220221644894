import { Confidence, type Scan } from '@graphql/generated';
import { UNKNOWN } from './MultiSelect';
import { NON_UNIFY, UNIFY } from './constans';

export const sliceLinks = (ids: string[], selectedMaxValue: number): string[] => {
  if (ids.length === 0) return [];

  const links = [];
  const uniqueIds = Array.from(new Set(ids));

  for (let i = 0; i < uniqueIds.length; i += selectedMaxValue) {
    const chunk = uniqueIds.slice(i, i + selectedMaxValue);
    const currentId = chunk[0];
    const analyzedEntitiesIds = chunk.join('%2C');
    const newLink = `${process.env.REACT_APP_SSO_REDIRECT_URI}main?analyzedEntitiesIds=${analyzedEntitiesIds}&currentId=${currentId}`;

    links.push(newLink);
  }

  return links;
};

export const createLinks = ({
  selectedScanDates,
  selectedMaxValue,
  selectedDate,
  selectedFrequencies,
  selectedMethods,
  selectedSiteStatus,
  selectedConfidence,
  selectedUnify,
  selectedPipelines,
  selectedCreatedAt
}: {
  selectedScanDates: Scan[];
  selectedMaxValue: number;
  selectedDate: string[];
  selectedFrequencies: string[];
  selectedMethods: string[];
  selectedSiteStatus: string[];
  selectedConfidence: string[];
  selectedUnify: string[];
  selectedPipelines: string[];
  selectedCreatedAt: string[];
}): { links: string[]; count: number } => {
  if (!selectedScanDates?.length || !selectedDate.length) return { links: [], count: 0 };
  const entities = selectedScanDates
    .filter((s) => {
      return selectedDate.includes(s.scan_date);
    })
    .filter((scan) => {
      return (
        (selectedUnify.includes(UNIFY) && scan.is_unified) ||
        (selectedUnify.includes(NON_UNIFY) && !scan.is_unified)
      );
    })
    .filter((scan) => {
      if (selectedMethods.length === 0) return true;
      return scan.scan_frequencies?.items?.some((frequency) => {
        if (selectedMethods.includes(UNKNOWN) && frequency?.transmitters?.items?.length === 0) {
          return true;
        }
        return frequency?.transmitters?.items?.some((transmitter) => {
          if (selectedMethods.includes(UNKNOWN) && !transmitter?.method) {
            return true;
          }
          if (selectedMethods.includes(transmitter?.method || '')) {
            return selectedMethods.includes(transmitter?.method || '');
          }
        });
      });
    })
    .sort((a, b) => new Date(a.scan_date).getTime() - new Date(b.scan_date).getTime())
    .map((scan) => {
      return scan.analyzed_entities?.items || [];
    })
    .flat()
    .filter((entity) => {
      const frequencyMatch =
        selectedFrequencies.length === 0 ||
        selectedFrequencies.includes(entity?.frequency?.toString() || '');

      return frequencyMatch;
    })
    .filter((entity) => {
      const confidenceMatch =
        selectedConfidence.length === 0 || selectedConfidence.includes(entity?.confidence || '');
      return confidenceMatch;
    })
    .filter((entity) => {
      if (selectedPipelines.includes(UNKNOWN) && !entity?.pipeline_name) return true;
      const pipelineMatch =
        selectedPipelines.length === 0 || selectedPipelines.includes(entity?.pipeline_name || '');
      return pipelineMatch;
    })
    .filter((entity) => {
      const createdAtMatch =
        selectedCreatedAt.length === 0 ||
        selectedCreatedAt.some((date) => entity?.createdAt?.includes(date));
      return createdAtMatch;
    })
    .sort((a, b) => {
      if (a?.confidence === b?.confidence) return 0;
      const confidenceOrder = [
        Confidence.Highest,
        Confidence.High,
        Confidence.Medium,
        Confidence.Low,
        Confidence.Lowest,
        Confidence.None
      ];
      const confidenceA = confidenceOrder.indexOf(a?.confidence || Confidence.None);
      const confidenceB = confidenceOrder.indexOf(b?.confidence || Confidence.None);
      return confidenceA - confidenceB;
    })
    .filter((entity) => {
      return selectedSiteStatus.includes(entity?.status || '');
    })
    .map((item) => item?.id)
    .filter((item): item is string => item !== undefined && item !== null);

  const links = sliceLinks(entities, selectedMaxValue);

  return { links, count: entities.length };
};
