import React from 'react';
import { FeatureCollection } from 'geojson';
import { PathOptions } from 'leaflet';
import GeoJsonWithUpdates from '@components/Map/GeoJsonWithUpdates';
import { useScanPathStore } from '@store'; // Import the store

const scanPathStyle: PathOptions = {
  color: 'black',
  dashArray: '5, 5',
  weight: 1
};

const ScanPath: React.FC = () => {
  const scanPathData = useScanPathStore((state) => state.scanPath);

  if (!scanPathData) {
    return null;
  }
  if (scanPathData && scanPathData?.features?.length === 1) {
    const color = scanPathData.features[0].properties?.color;
    return (
      <GeoJsonWithUpdates
        data={scanPathData}
        style={{ ...scanPathStyle, color: color || 'black' }}
      />
    );
  }
  return (
    <>
      {scanPathData.features.map((feature, index) => (
        <GeoJsonWithUpdates
          key={index}
          data={feature}
          style={{ ...scanPathStyle, color: feature?.properties?.color || 'black' }}
        />
      ))}
    </>
  );
};

export default ScanPath;
