import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import { Signals } from '@pages/Main/DetailsView/CrossingViews/constans';
import { useMemo, useEffect, useContext } from 'react';
import Select, { MultiValue } from 'react-select';

export type PeaksOption = {
  value: string;
  label: string;
};

export const PeaksSelect = ({
  peaks,
  onChange,
  selectedPeaks
}: {
  peaks: Record<string, any> | undefined;
  onChange: (newValue: MultiValue<PeaksOption>) => void;
  selectedPeaks: PeaksOption[];
}) => {
  const { analyzedEntity } = useContext(AnalyzedEntityContext);
  const options = useMemo(() => {
    if (!peaks) return [];
    const peakOptions = Object.keys(peaks).map((key) => ({
      label: key,
      value: key
    }));

    return [{ label: 'Select All', value: 'SELECT_ALL' }, ...peakOptions];
  }, [peaks]);
  useEffect(() => {
    if (peaks && selectedPeaks.length === 0) {
      const specificPeaks = options.filter(
        (opt) => opt.value === Signals.YAmplitude1Peak || opt.value === Signals.ZAmplitude1Peak
      );
      onChange(specificPeaks);
    }
  }, [analyzedEntity?.id, peaks]);
  const handleChange = (newValue: MultiValue<PeaksOption>) => {
    if (newValue.some((opt) => opt.value === 'SELECT_ALL')) {
      onChange(options.filter((opt) => opt.value !== 'SELECT_ALL'));
    } else {
      onChange(newValue);
    }
  };

  return (
    <Select
      options={options}
      value={selectedPeaks}
      isMulti
      onChange={handleChange}
      placeholder="Select peaks"
      menuPosition="fixed"
      menuPlacement="top"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          maxWidth: 200,
          width: 200
        }),
        multiValue: (base) => ({
          ...base,
          minWidth: '120px',
          maxWidth: '200px'
        }),
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '180px',
          title: base.children
        })
      }}
    />
  );
};
